import React, { useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"


// Components
import SEO from "../components/SEO"
import CenterTitle from "../components/CenterTitle"
import LocationList from "../modules/LocationList/LocationList"

// Helpers & Content
import { locationsContent } from "../utils/content/locationsContent"
import { fireEvent } from "../utils/helpers"

// markup
const LocationPage = () => {
  const { formatMessage } = useIntl()
  const { seo, kpiCards } = locationsContent

  useEffect(() => {
    fireEvent("nav-scheme", "dark")
  }, [])

  return (
    <div>
      <SEO title={formatMessage({ id: seo.metaTitle })} description={formatMessage({ id: seo.metaDescription })} />
      <CenterTitle
        content={{ header: kpiCards.header, text: kpiCards.text, lottie: "https://assets7.lottiefiles.com/packages/lf20_itvqeyfd.json" }}
      />
      <LocationList content={kpiCards} />
    </div>
  )
}

export default LocationPage
