export const locationsContent = {
  seo: {
    metaTitle: "metaTitleWhereToFindUs",
    metaDescription: "metaDescriptionWhereToFindUs",
  },
  kpiCards: {
    header: "locationsKpiCardsTitle",
    text: "locationsKpiCardsText",
    color: "",
    kpis: [
      {
        title: "Austria",
        content: [
          {
            title: "Bad-Ischl",
            subtitle: "",
            image: "",
          },
          {
            title: "Innsbruck",
            subtitle: "",
            image: "",
          },
          {
            title: "Korneuburg",
            subtitle: "",
            image: "",
          },
          {
            title: "Klagenfurt",
            subtitle: "",
            image: "",
          },
          {
            title: "Linz",
            subtitle: "",
            image: "",
          },
          {
            title: "Velden\n(Pop-Up City)",
            subtitle: "",
            image: "",
          },
          {
            title: "Vienna",
            subtitle: "",
            image: "",
          },
          {
            title: "Villach",
            subtitle: "",
            image: "",
          },
          {
            title: "Waidhofen an der Ybbs",
            subtitle: "",
            image: "",
          },
          {
            title: "Wels",
            subtitle: "",
            image: "",
          },
        ],
      },
      {
        title: "Bahrain",
        content: [
          {
            title: "Manama",
            subtitle: "",
            image: "",
          },
        ],
      },
      {
        title: "Denmark",
        content: [
          {
            title: "Copenhaguen",
            subtitle: "",
            image: "",
          },
          {
            title: "Glostrup",
            subtitle: "",
            image: "",
          },
          {
            title: "Herning",
            subtitle: "",
            image: "",
          },
          {
            title: "Odense",
            subtitle: "",
            image: "",
          },
        ],
      },
      {
        title: "Finland",
        content: [
          {
            title: "Helsinki",
            subtitle: "",
            image: "",
          },
          {
            title: "Jyväskylä",
            subtitle: "",
            image: "",
          },
          {
            title: "Lathi",
            subtitle: "",
            image: "",
          },
          {
            title: "Nokia",
            subtitle: "",
            image: "",
          },
          {
            title: "Tampere",
            subtitle: "",
            image: "",
          },
          {
            title: "Turku",
            subtitle: "",
            image: "",
          },
          {
            title: "Vaasa",
            subtitle: "",
            image: "",
          },
          {
            title: "Vantaa",
            subtitle: "",
            image: "",
          },
        ],
      },
      {
        title: "France",
        content: [
          {
            title: "Bordeaux",
            subtitle: "",
            image: "",
          },
          {
            title: "Grenoble",
            subtitle: "",
            image: "",
          },
          {
            title: "Lyon",
            subtitle: "",
            image: "",
          },
          {
            title: "Paris",
            subtitle: "",
            image: "",
          },
          {
            title: "Saint-Quentin en Yvelines",
            subtitle: "",
            image: "",
          },
          {
            title: "Val D'Isere\n(Pop-Up City)",
            subtitle: "",
            image: "",
          },
        ],
      },
      {
        title: "Germany",
        content: [
          {
            title: "Aachen",
            subtitle: "",
            image: "",
          },
          {
            title: "Berlin",
            subtitle: "",
            image: "",
          },
          {
            title: "Bielefeld",
            subtitle: "",
            image: "",
          },
          {
            title: "Bochum",
            subtitle: "",
            image: "",
          },
          {
            title: "Bonn",
            subtitle: "",
            image: "",
          },
          {
            title: "Braunschweig",
            subtitle: "",
            image: "",
          },
          {
            title: "Bremen",
            subtitle: "",
            image: "",
          },
          {
            title: "Celle",
            subtitle: "",
            image: "",
          },
          {
            title: "Chemnitz",
            subtitle: "",
            image: "",
          },
          {
            title: "Cologne",
            subtitle: "",
            image: "",
          },
          {
            title: "Darmstadt",
            subtitle: "",
            image: "",
          },
          {
            title: "Dortmund",
            subtitle: "",
            image: "",
          },
          {
            title: "Düsseldorf",
            subtitle: "",
            image: "",
          },
          {
            title: "Erlangen",
            subtitle: "",
            image: "",
          },
          {
            title: "Essen",
            subtitle: "",
            image: "",
          },
          {
            title: "Fürth",
            subtitle: "",
            image: "",
          },
          {
            title: "Frankfurt a. M.",
            subtitle: "",
            image: "",
          },
          {
            title: "Flensburg",
            subtitle: "",
            image: "",
          },
          {
            title: "Gelsenkirchen",
            subtitle: "",
            image: "",
          },
          {
            title: "Gütersloh",
            subtitle: "",
            image: "",
          },
          {
            title: "Halle (Saale)",
            subtitle: "",
            image: "",
          },
          {
            title: "Hamburg",
            subtitle: "",
            image: "",
          },
          {
            title: "Hannover",
            subtitle: "",
            image: "",
          },
          {
            title: "Heidelberg",
            subtitle: "",
            image: "",
          },
          {
            title: "Heilbronn",
            subtitle: "",
            image: "",
          },
          {
            title: "Herford",
            subtitle: "",
            image: "",
          },
          {
            title: "Hildesheim",
            subtitle: "",
            image: "",
          },
          {
            title: "Ingolstadt",
            subtitle: "",
            image: "",
          },
          {
            title: "Jena-Zwickau-Gera",
            subtitle: "",
            image: "",
          },
          {
            title: "Kaiserslautern",
            subtitle: "",
            image: "",
          },
          {
            title: "Karlsruhe",
            subtitle: "",
            image: "",
          },
          {
            title: "Kassel",
            subtitle: "",
            image: "",
          },
          {
            title: "Kiel",
            subtitle: "",
            image: "",
          },
          {
            title: "Lindau\n(Pop-Up City)",
            subtitle: "",
            image: "",
          },
          {
            title: "Ludwigsburg",
            subtitle: "",
            image: "",
          },
          {
            title: "Ludwigshafen",
            subtitle: "",
            image: "",
          },
          {
            title: "Luebeck",
            subtitle: "",
            image: "",
          },
          {
            title: "Mainz",
            subtitle: "",
            image: "",
          },
          {
            title: "Mönchengladbach",
            subtitle: "",
            image: "",
          },
          {
            title: "Mülheim",
            subtitle: "",
            image: "",
          },
          {
            title: "München",
            subtitle: "",
            image: "",
          },
          {
            title: "Münster",
            subtitle: "",
            image: "",
          },
          {
            title: "Nürnberg",
            subtitle: "",
            image: "",
          },
          {
            title: "Osnabrück",
            subtitle: "",
            image: "",
          },
          {
            title: "Oldenburg",
            subtitle: "",
            image: "",
          },
          {
            title: "Paderborn",
            subtitle: "",
            image: "",
          },
          {
            title: "Potsdam",
            subtitle: "",
            image: "",
          },
          {
            title: "Recklinghausen",
            subtitle: "",
            image: "",
          },
          {
            title: "Reutlingen",
            subtitle: "",
            image: "",
          },
          {
            title: "Rostock",
            subtitle: "",
            image: "",
          },
          {
            title: "Saarbrücken",
            subtitle: "",
            image: "",
          },
          {
            title: "Salzgitter",
            subtitle: "",
            image: "",
          },
          {
            title: "Sankt-Augustin",
            subtitle: "",
            image: "",
          },
          {
            title: "Solingen",
            subtitle: "",
            image: "",
          },
          {
            title: "Stuttgart",
            subtitle: "",
            image: "",
          },
          {
            title: "Troisdorf",
            subtitle: "",
            image: "",
          },
          {
            title: "Ulm & Neu-Ulm",
            subtitle: "",
            image: "",
          },
          {
            title: "Wiesbaden",
            subtitle: "",
            image: "",
          },
          {
            title: "Zwickau",
            subtitle: "",
            image: "",
          },
          {
            title: "Wolfsburg",
            subtitle: "",
            image: "",
          },
          {
            title: "Dornbirn",
            subtitle: "",
            image: "",
          },
        ],
      },
      {
        title: "Netherlands",
        content: [
          {
            title: "Utrecht",
            subtitle: "",
            image: "",
          },
          {
            title: "Eindhoven",
            subtitle: "",
            image: "",
          },
        ],
      },
      {
        title: "Norway",
        content: [
          {
            title: "Asker",
            subtitle: "",
            image: "",
          },
          {
            title: "Baerum",
            subtitle: "",
            image: "",
          },
          {
            title: "Bergen",
            subtitle: "",
            image: "",
          },
          {
            title: "Drammen",
            subtitle: "",
            image: "",
          },
          {
            title: "Jessheim",
            subtitle: "",
            image: "",
          },
          {
            title: "Lillestrøm",
            subtitle: "",
            image: "",
          },
          {
            title: "Lorenskog",
            subtitle: "",
            image: "",
          },
          {
            title: "Namsos",
            subtitle: "",
            image: "",
          },
          {
            title: "Oslo",
            subtitle: "",
            image: "",
          },
          {
            title: "Ski",
            subtitle: "",
            image: "",
          },
          {
            title: "Stavanger",
            subtitle: "",
            image: "",
          },
          {
            title: "Trondheim",
            subtitle: "",
            image: "",
          },
        ],
      },
      {
        title: "Hungary",
        content: [
          {
            title: "Budapest",
            subtitle: "",
            image: "",
          },
        ],
      },
      {
        title: "Poland",
        content: [
          {
            title: "Chrzanów + Trzebinia\n(Long-Tail City)",
            subtitle: "",
            image: "",
          },
          {
            title: "Gdansk",
            subtitle: "",
            image: "",
          },
          {
            title: "Gorzow-Wielkopolski",
            subtitle: "",
            image: "",
          },
          {
            title: "Kolobrzeg",
            subtitle: "",
            image: "",
          },
          {
            title: "Krakow",
            subtitle: "",
            image: "",
          },
          {
            title: "Międzyzdroje\n(Pop-Up City)",
            subtitle: "",
            image: "",
          },
          {
            title: "Oświęcim\n(Long-Tail City)",
            subtitle: "",
            image: "",
          },
          {
            title: "Plock",
            subtitle: "",
            image: "",
          },
          {
            title: "Tczew",
            subtitle: "",
            image: "",
          },
          {
            title: "Tricity",
            subtitle: "",
            image: "",
          },
          {
            title: "Warsaw",
            subtitle: "",
            image: "",
          },
          {
            title: "Wladyslawowo",
            subtitle: "",
            image: "",
          },
        ],
      },
      {
        title: "Quatar",
        content: [
          {
            title: "Doha",
            subtitle: "",
            image: "",
          },
        ],
      },
      {
        title: "Sweden",
        content: [
          {
            title: "Borås",
            subtitle: "",
            image: "",
          },
          {
            title: "Eskilstuna Borås",
            subtitle: "",
            image: "",
          },
          {
            title: "Gothenburg",
            subtitle: "",
            image: "",
          },
          {
            title: "Halmstad",
            subtitle: "",
            image: "",
          },
          {
            title: "Helsingborg",
            subtitle: "",
            image: "",
          },
          {
            title: "Jönköping",
            subtitle: "",
            image: "",
          },
          {
            title: "Karlstad",
            subtitle: "",
            image: "",
          },
          {
            title: "Linköping",
            subtitle: "",
            image: "",
          },
          {
            title: "Lund",
            subtitle: "",
            image: "",
          },
          {
            title: "Malmö",
            subtitle: "",
            image: "",
          },
          {
            title: "Norrköping",
            subtitle: "",
            image: "",
          },
          {
            title: "Örebro",
            subtitle: "",
            image: "",
          },
          {
            title: "Stockholm",
            subtitle: "",
            image: "",
          },
          {
            title: "Uppsala",
            subtitle: "",
            image: "",
          },
          {
            title: "Västeras",
            subtitle: "",
            image: "",
          },
        ],
      },
      {
        title: "Switzerland",
        content: [
          {
            title: "Basel",
            subtitle: "",
            image: "",
          },
          {
            title: "Bern",
            subtitle: "",
            image: "",
          },
          {
            title: "St. Gallen",
            subtitle: "",
            image: "",
          },
          {
            title: "Winterhur",
            subtitle: "",
            image: "",
          },
          {
            title: "Zug",
            subtitle: "",
            image: "",
          },
          {
            title: "Zürich",
            subtitle: "",
            image: "",
          },
        ],
      },
      {
        title: "United Arab Emirates",
        content: [
          {
            title: "Abu Dhabi",
            subtitle: "",
            image: "",
          },
          {
            title: "Dubai",
            subtitle: "",
            image: "",
          },
        ],
      },
      {
        title: "United Kingdom",
        content: [
          {
            title: "London",
            subtitle: "",
            image: "",
          },
          {
            title: "York",
            subtitle: "",
            image: "",
          },
        ],
      },
    ],
  },
}
