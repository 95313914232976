import React from "react"
import PropTypes from "prop-types"

// Components
import Container from "../../components/containers/Container"
import Accordion from "../Accordion/Accordion"

const LocationList = ({ content }) => {
  const { kpis } = content

  return (
    <Container className="flex flex-col items-center">
      <Accordion items={kpis} variant="kpi" />
    </Container>
  )
}

LocationList.propTypes = {
  /**
   * Prop for the background color, passed as a tailwind class
   */
  content: PropTypes.object.isRequired,
}

export default LocationList
